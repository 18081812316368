import PosthogFlags from '@/types/posthog-flags';
import { usePostHog } from 'posthog-js/react';

/**
 * Experiment for displaying addresses outside of service areas in search results
 * * https://app.posthog.com/experiments/4986
 */
const useOutsideServiceAreaExperiment = () => {
  const posthog = usePostHog();

  return {
    isOutsideServiceAreaExperimentTreatment:
      posthog.getFeatureFlag(PosthogFlags.SearchOutsideServiceAreas) === 'test',
  };
};

export default useOutsideServiceAreaExperiment;
