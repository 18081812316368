import { useCallback, useState } from 'react';

type UseVisibilityProps = {
  initialValue?: boolean;
};

const useVisibility = ({ initialValue = false }: UseVisibilityProps = {}) => {
  const [visibility, setVisibility] = useState(initialValue);

  const show = useCallback(() => {
    setVisibility(true);
  }, []);

  const hide = useCallback(() => {
    setVisibility(false);
  }, []);

  return [visibility, show, hide] as const;
};

export default useVisibility;
