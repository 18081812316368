import { useNotification } from '@drivekyte/web-components';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import useTranslation from '@/hooks/use-translation';
import request, { ApiResponse, RequestError } from '@/utils/request';
import {
  TimeSlots,
  TimeSlotsApiResponse,
  UseTimeSlotsPayload,
} from '../../types';
import useTimeSlotsTransformer from '../use-time-slots-transformer';

type UseFetchTimeSlotsParams = UseTimeSlotsPayload & {
  enableV3?: boolean;
  isEvaluatingV3Flag?: boolean;
};

const fetchTimeSlots = async (payload: UseFetchTimeSlotsParams) => {
  const v2Payload = {
    end_address: payload.endAddress,
    end_date: dayjs(payload.endDate).format('YYYY-MM-DD'),
    start_address: payload.startAddress,
    start_date: dayjs(payload.startDate).format('YYYY-MM-DD'),
    start_handover_type: payload.startHandoverType,
    end_handover_type: payload.endHandoverType,
  };

  const v3Payload = {
    context: 'unknown',
    start_date: dayjs(payload.startDate).format('YYYY-MM-DD'),
    end_date: dayjs(payload.endDate).format('YYYY-MM-DD'),
    start_service_area_uuid: payload.serviceAreaUUID,
    end_service_area_uuid: payload.endServiceAreaUUID,
  };

  const { data: response } = await request<ApiResponse<TimeSlotsApiResponse>>({
    url: `v${payload.enableV3 ? 3 : 2}/rentals/timeslots`,
    params: payload.enableV3 ? v3Payload : v2Payload,
  });

  return response.data;
};

const useFetchTimeSlots = (payload: UseFetchTimeSlotsParams) => {
  const { t } = useTranslation('global');
  const timeSlotsTransformer = useTimeSlotsTransformer();
  const notify = useNotification();

  const startDateFormatted = dayjs(payload.startDate).format('YYYY-MM-DD');
  const endDateFormatted = dayjs(payload.endDate).format('YYYY-MM-DD');

  const hasAddresses = !!payload.startAddress && !!payload.endAddress;
  const hasServiceAreaUUIDs =
    !!payload.serviceAreaUUID && !!payload.endServiceAreaUUID;
  const hasNecessaryPayload = hasAddresses || hasServiceAreaUUIDs;

  const enabled =
    !payload.disabled && !payload.isEvaluatingV3Flag && hasNecessaryPayload;

  return useQuery<TimeSlotsApiResponse, RequestError, TimeSlots>(
    [
      'timeSlots',
      startDateFormatted,
      endDateFormatted,
      payload.startAddress,
      payload.endAddress,
      payload.serviceAreaUUID,
      payload.endServiceAreaUUID,
      payload.startHandoverType,
      payload.endHandoverType,
    ],
    () => fetchTimeSlots(payload),
    {
      enabled,
      retry: false,
      select: (data) => {
        const transformed = timeSlotsTransformer(data);

        const start = dayjs(payload.startDate);
        const end = dayjs(payload.endDate);

        if (start.isSame(end, 'day')) {
          return {
            ...transformed,
            end: {
              ...transformed.end,
              timeSlots: transformed.end.timeSlots.filter((_timeSlot) =>
                dayjs(_timeSlot.value).isAfter(start),
              ),
            },
          };
        }

        return transformed;
      },
      onError: (error) => {
        notify({
          variant: 'error',
          titleText: t('errors.generic.error.title'),
          descriptionText: error.response?.data.status ?? error.message,
        });
      },
    },
  );
};

export default useFetchTimeSlots;
