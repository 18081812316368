import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useSessionStorage } from 'react-use';
import { HIDE_ONBOARDING_POPUP_FOR_UTM_SOURCE } from '@/config/global-constants';

const KAYAK_ID_KEY = 'kayakclickid';

const usePartnerReferral = () => {
  const { query } = useRouter();
  const [partnerName, setPartnerName] = useSessionStorage(
    'partner',
    null as null | string,
  );

  const utmSource = query.utm_source;
  const onboardingDone = !!query.onboarding_done;
  const isFromKayak = useMemo(() => utmSource?.includes('kayak'), [utmSource]);

  if (!partnerName && utmSource?.includes('priceline'))
    setPartnerName('priceline');

  const trackingId: string | string[] | undefined = useMemo(() => {
    if (isFromKayak) return query[KAYAK_ID_KEY];

    return undefined;
  }, [query, isFromKayak]);

  let hasReferral = false;

  if (typeof utmSource === 'object') {
    hasReferral =
      !HIDE_ONBOARDING_POPUP_FOR_UTM_SOURCE.some((source) =>
        utmSource.includes(source),
      ) || Boolean(trackingId);
  } else {
    hasReferral =
      (utmSource &&
        !HIDE_ONBOARDING_POPUP_FOR_UTM_SOURCE.includes(utmSource)) ||
      Boolean(trackingId);
  }

  const hasToRefineAddress = !!hasReferral && !query.refined_address;

  return {
    hasToRefineAddress,
    hasReferral,
    onboardingDone,
    referral: hasReferral
      ? {
          source: utmSource,
          tracking_id: trackingId,
        }
      : undefined,
    isPriceline: partnerName === 'priceline',
    partnerName,
    setPartnerName,
  };
};

export default usePartnerReferral;
