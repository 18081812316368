import { API_BASE_URL, REQUEST_TIMEOUT_MS } from '@/config/global-constants';
import { getAnonymousId } from '@/utils/analytics/analytics';

const usePlacesSearchConfig = () => ({
  baseURL: API_BASE_URL,
  timeout: REQUEST_TIMEOUT_MS,
  withCredentials: true,
  headers: {
    'X-Client-ID': 'consumer-web',
    'X-Anon-ID': getAnonymousId(),
  },
});

export default usePlacesSearchConfig;
