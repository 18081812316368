import { Place } from '@drivekyte/use-places-search';
import { BottomSheet } from '@drivekyte/web-components';
import dynamic from 'next/dynamic';

const SearchAddressModalContent = dynamic(() =>
  import('./components/search-address-modal-content'),
);

type SearchAddressModalProps = {
  title: string;
  searchDefaultValue?: string;
  visible: boolean;
  onClose: () => void;
  onSelect: (address: Place) => void;
  isEditTrip?: boolean;
  inputPlaceholder?: string;
  onAutoCompleteDataUpdate?: (address: string) => void;
};

const SearchAddressModal = ({
  title,
  visible,
  onClose,
  onSelect,
  searchDefaultValue,
  isEditTrip,
  inputPlaceholder,
  onAutoCompleteDataUpdate,
}: SearchAddressModalProps) => (
  <BottomSheet
    height="98%"
    zIndex={100}
    onClose={onClose}
    open={visible}
    title={title}
    leftItemProps={{
      onPress: onClose,
      iconName: '24-close-big',
    }}
    contentStyle={{
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: 'calc(100% - 96px)',
    }}
  >
    <SearchAddressModalContent
      onClose={onClose}
      onSelect={onSelect}
      onAutoCompleteDataUpdate={onAutoCompleteDataUpdate}
      visible={visible}
      searchDefaultValue={searchDefaultValue}
      isEditTrip={isEditTrip}
      inputPlaceholder={inputPlaceholder}
    />
  </BottomSheet>
);

export default SearchAddressModal;
