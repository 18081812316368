import { useEffect } from 'react';
import { useDateFormatter } from '@drivekyte/date-utils';
import { QueryBookingCart } from '@/types/booking-cart';
import DcsFlags, { TimeslotsForceUpdate } from '@/types/dcs-flags';
import useDcsFlag from '../use-dcs-flag/use-dcs-flag';
import useNextAvailableTimeslot from '../use-next-available-timeslot/use-next-available-timeslot';
import { TimeSlotTimestamp } from '../use-time-slots/types';
import dayjs from 'dayjs';
import { useNotification } from '@drivekyte/web-components';
import useTranslation from '../use-translation';

type Dates = {
  startDate: Date;
  endDate: Date;
};

type UseCalendarValidDatesProps = {
  startTimeSlotsTimestamps?: TimeSlotTimestamp[];
  endTimeSlotsTimestamps?: TimeSlotTimestamp[];
  startTz?: string;
  endTz?: string;
  selectedDates: Dates;
  onChange: (nextValues: Partial<QueryBookingCart>) => void;
  disableStartTimeslotAlteration?: boolean;
  disableEndTimeslotAlteration?: boolean;
};

const useCalendarValidDates = ({
  startTimeSlotsTimestamps,
  endTimeSlotsTimestamps,
  disableStartTimeslotAlteration,
  disableEndTimeslotAlteration,
  startTz,
  endTz,
  selectedDates,
  onChange,
}: UseCalendarValidDatesProps) => {
  const notify = useNotification();
  const { t } = useTranslation('global.timeslot-adjusted');
  const { formatISOWithoutTimezone } = useDateFormatter();
  const { getNextAvailableTimeslot } = useNextAvailableTimeslot();
  const { data: timeSlotsForceUpdateFlag } = useDcsFlag<TimeslotsForceUpdate>({
    flag: DcsFlags.TimeSlotsForceUpdate,
  });

  const arraysAreValid = !!(
    startTimeSlotsTimestamps?.length && endTimeSlotsTimestamps?.length
  );

  const startTimeslotsJSON = JSON.stringify(startTimeSlotsTimestamps);
  const endTimeslotsJSON = JSON.stringify(endTimeSlotsTimestamps);

  useEffect(() => {
    if (arraysAreValid && !!startTz && !!endTz) {
      if (!!timeSlotsForceUpdateFlag) {
        const nextStartTimeslot = getNextAvailableTimeslot(
          selectedDates.startDate,
          startTimeSlotsTimestamps,
        );

        const filteredEndTimeSlots = endTimeSlotsTimestamps.filter(
          (ts) => ts?.value > nextStartTimeslot?.value,
        );

        const nextEndTimeslot = getNextAvailableTimeslot(
          selectedDates.endDate,
          filteredEndTimeSlots,
        );

        const nextDates = {
          startDate:
            nextStartTimeslot?.value && !disableStartTimeslotAlteration
              ? new Date(nextStartTimeslot?.value)
              : selectedDates.startDate,
          endDate:
            nextEndTimeslot?.value && !disableEndTimeslotAlteration
              ? new Date(nextEndTimeslot?.value)
              : selectedDates.endDate,
        };

        const isStartTimeChanging = !dayjs(nextStartTimeslot.value).isSame(
          dayjs(selectedDates.startDate),
        );
        const isEndTimeChanging = !dayjs(nextEndTimeslot.value).isSame(
          dayjs(selectedDates.endDate),
        );

        if (isStartTimeChanging || isEndTimeChanging) {
          notify({
            variant: 'info',
            titleText: t('title'),
            descriptionText: t('description'),
          });
        }

        onChange({
          start_date: formatISOWithoutTimezone(nextDates.startDate),
          end_date: formatISOWithoutTimezone(nextDates.endDate),
          start_timezone: startTz,
          end_timezone: endTz,
        });

        return;
      }

      onChange({
        start_timezone: startTz,
        end_timezone: endTz,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSlotsForceUpdateFlag, startTimeslotsJSON, endTimeslotsJSON]);
};

export default useCalendarValidDates;
