import React from 'react';
import { Place } from '@drivekyte/use-places-search';
import { Box, Divider } from '@drivekyte/web-components';
import useSuggestedHandover, {
  HandoverOutpostType,
} from '@/hooks/use-suggested-handover';
import useTranslation from '@/hooks/use-translation';
import { QueryBookingCart } from '@/types/booking-cart';
import { getIsMappingToLot } from '@/utils/get-mapping-to-lot/get-is-mapping-to-lot';
import SameLocationToggler from './components/same-location-toggler';
import WhereItem from './components/where-item';

type WhereProps = {
  startAddress?: string;
  endAddress?: string;
  isSameDeliveryReturnLocations: boolean;
  onChangeAddress: (nextAddressQuery: Partial<QueryBookingCart>) => void;
  onChangeAddressToggle: (nextValue: boolean) => void;
};

const Where = ({
  startAddress = '',
  endAddress = '',
  isSameDeliveryReturnLocations,
  onChangeAddress,
  onChangeAddressToggle,
}: WhereProps) => {
  const { t } = useTranslation('pages.landing-page.search-box.where');
  const { checkHandoverAddress } = useSuggestedHandover();

  const handleChangeAddress = (key: string) => (place: Place) => {
    const address = place.map_to?.display_text || place.display_text;
    const isMappingToPickupAtLot = getIsMappingToLot(place);

    if (isSameDeliveryReturnLocations) {
      onChangeAddress({
        start_address: address,
        end_address: address,
        start_place: place.display_text,
        end_place: place.display_text,
        service_area_uuid: place.service_area_uuid,
        service_area_code: place.service_area_code,
        end_service_area_uuid: place.service_area_uuid,
        end_service_area_code: place.service_area_code,
        is_mapping_start_to_pickup_at_lot: isMappingToPickupAtLot,
        is_mapping_end_to_pickup_at_lot: isMappingToPickupAtLot,
      });
      checkHandoverAddress(HandoverOutpostType.DELIVERY, place);
    } else {
      onChangeAddress({
        [`${key}_address`]: address,
        [`${key}_place`]: place.display_text,
        [`${key === 'start' ? '' : `${key}_`}service_area_uuid`]:
          place.service_area_uuid,
        [`${key === 'start' ? '' : `${key}_`}service_area_code`]:
          place.service_area_code,
        [`is_mapping_${key}_to_pickup_at_lot`]: isMappingToPickupAtLot,
      });
      checkHandoverAddress(
        key === 'start'
          ? HandoverOutpostType.DELIVERY
          : HandoverOutpostType.RETURN,
        place,
      );
    }
  };

  return (
    <Box
      borderRadius="base"
      borderColor="primary"
      borderWidth="large"
      backgroundColor="elevated"
      borderBottomWidth="large"
      boxShadow="base"
      borderStyle="solid"
    >
      {isSameDeliveryReturnLocations ? (
        <WhereItem
          title={t('delivery-and-return.title')}
          iconName="16-pin"
          placeholder={t('placeholder')}
          onChange={handleChangeAddress('start')}
          value={startAddress}
          testID="responsive-address-search-input"
        />
      ) : (
        <>
          <WhereItem
            title={t('delivery.title')}
            iconName="16-pin"
            placeholder={t('placeholder')}
            onChange={handleChangeAddress('start')}
            value={startAddress}
          />
          <Divider />
          <WhereItem
            title={t('return.title')}
            iconName="16-end-location"
            placeholder={t('placeholder')}
            onChange={handleChangeAddress('end')}
            value={endAddress}
          />
        </>
      )}
      <Divider />
      <SameLocationToggler
        value={!!isSameDeliveryReturnLocations}
        onChange={onChangeAddressToggle}
      />
    </Box>
  );
};

export default Where;
