import { usePostHog } from 'posthog-js/react';
import PosthogFlags from '@/types/posthog-flags';

/**
 * Experiment for displaying strikethrough prices on car-picker and checkout
 * if there is a coupon applied to the booking.
 * * https://us.posthog.com/project/86379/experiments/58172
 */
const useMerchandisingStrategyExperiment = () => {
  const posthog = usePostHog();

  return {
    isMerchandisingStrategyExperiment:
      posthog.getFeatureFlag(PosthogFlags.MerchandisingStrategy) === 'test',
  };
};

export default useMerchandisingStrategyExperiment;
