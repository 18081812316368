import { AxiosRequestConfig } from 'axios';
import usePlacesSearchBase, {
  Place as PlaceBase,
  ExclusionZone as ExclusionZoneBase,
} from '@drivekyte/use-places-search';
import usePlacesSearchConfig from '@/config/search/use-places-search-config';
import {
  CACHE_DURATION_MS,
  STALE_DURATION_MS,
} from '@/constants/requests-configuration';

const usePlacesSearch = (
  searchQuery: string,
  requestConfig?: AxiosRequestConfig,
) => {
  return usePlacesSearchBase(
    searchQuery,
    {
      ...usePlacesSearchConfig(),
      ...requestConfig,
    },
    {
      cacheTime: CACHE_DURATION_MS.medium,
      staleTime: STALE_DURATION_MS.medium,
    },
  );
};

export default usePlacesSearch;

export type Place = PlaceBase;
export type ExclusionZone = ExclusionZoneBase;
