import { useMerchandisingConfig } from '@/hooks/experiments/use-merchandising-config';
import usePromoCodeToLoadCodedata from '@/hooks/use-promo-code/use-promocode-to-load-codedata';
import useTranslation from '@/hooks/use-translation';
import useCurrencyFormatter from '@drivekyte/currency-utils';
import { ShoppingTag } from '@drivekyte/icons';
import { Animated, Box, Typography } from '@drivekyte/ui';
import { useEffect, useRef, useState } from 'react';

const AnimatedBox = Animated.createAnimatedComponent(Box);

const MerchandisingUniversalTopBar = () => {
  const { t } = useTranslation('pages.car-picker');
  const {
    data: promoCodeData,
    isLoading,
    error,
  } = usePromoCodeToLoadCodedata();

  const {
    data: merchandisingConfigData,
    isLoading: isLoadingMerchandisingConfig,
  } = useMerchandisingConfig(promoCodeData);

  const animation = useRef(new Animated.Value(0)).current;
  const [isComponentLoaded, setComponentLoaded] = useState(false);

  const currencyFormatter = useCurrencyFormatter();

  useEffect(() => {
    if (!isComponentLoaded) {
      setComponentLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (promoCodeData && !isLoadingMerchandisingConfig) {
      Animated.timing(animation, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true,
      }).start();
    }
  }, [promoCodeData, isLoadingMerchandisingConfig]);

  const getTranslation = () => {
    if (!promoCodeData) return '';

    if (!merchandisingConfigData)
      return t('discount-applied', { code: promoCodeData.code });

    return merchandisingConfigData?.topBannerCopy
      .replace(
        '{{value}}',
        promoCodeData.percent_off > 0
          ? promoCodeData.percent_off.toString()
          : currencyFormatter(promoCodeData.amount_off / 100),
      )
      .replace('{{code}}', promoCodeData.code);
  };

  if (
    !promoCodeData ||
    isLoading ||
    error ||
    !isComponentLoaded ||
    isLoadingMerchandisingConfig
  ) {
    return null;
  }

  return (
    <AnimatedBox
      opacity={animation.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1],
      })}
      marginTop={animation.interpolate({
        inputRange: [0, 1],
        outputRange: [-42, 0],
      })}
    >
      <Box
        paddingVertical="$xSmall"
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor="$backgroundPositiveSubtle"
        flexDirection="row"
      >
        <ShoppingTag width="12" height="12" color="black" />
        <Typography
          marginLeft="$tiny"
          variant="paragraphSmall"
          color="$contentPrimary"
          fontWeight="bold"
        >
          {getTranslation()}
        </Typography>
      </Box>
    </AnimatedBox>
  );
};

export default MerchandisingUniversalTopBar;
