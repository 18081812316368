import React from 'react';
import { Box, LoadingIndicator } from '@drivekyte/web-components';

type SearchEmptyStateLoadingProps = {
  testID?: string;
};

const SearchEmptyStateLoading = ({ testID }: SearchEmptyStateLoadingProps) => (
  <Box testID={testID} display="flex" justifyContent="center" padding="large">
    <LoadingIndicator />
  </Box>
);

export default SearchEmptyStateLoading;
