import { useCallback, useReducer } from 'react';

export enum InputStateActionType {
  SET_STATE = 'SET_STATE',
  DISABLE_ALL = 'DISABLE_ALL',
}

export type SearchInputState = {
  showEndAddress: boolean;
  showEndDate: boolean;
  showStartAddress: boolean;
  showStartDate: boolean;
};

type SearchInputStateAction = {
  type: InputStateActionType;
  payload?: Partial<SearchInputState>;
};

const reducer = (state: SearchInputState, action: SearchInputStateAction) => {
  switch (action.type) {
    case InputStateActionType.SET_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case InputStateActionType.DISABLE_ALL:
      return {
        showEndAddress: false,
        showEndDate: false,
        showStartAddress: false,
        showStartDate: false,
      };
  }
};

const useSearchInputState = () => {
  const [inputState, dispatch] = useReducer(reducer, {
    showEndAddress: false,
    showEndDate: false,
    showStartAddress: true,
    showStartDate: false,
  });

  const setInputState = useCallback(
    (newState: Partial<SearchInputState>) =>
      dispatch({ type: InputStateActionType.SET_STATE, payload: newState }),
    [],
  );
  const resetInputState = useCallback(
    () => dispatch({ type: InputStateActionType.DISABLE_ALL }),
    [],
  );

  return {
    inputState,
    setInputState,
    resetInputState,
  };
};

export default useSearchInputState;
