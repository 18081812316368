import dayjs, { extend } from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsUTC from 'dayjs/plugin/utc';

extend(dayjsUTC);
extend(dayjsTimezone);

export const getDateInTz = (ms: number, timeZone: string) => {
  const formattedDate = new Date(ms).toLocaleString('en-US', {
    timeZone,
  });

  return new Date(formattedDate);
};

export const formatDateTime = (date: Date) =>
  dayjs(date).format('MMM DD, hh:mm A');

export const formatDateTimeRange = (date: Date, minutesRange = 30) => {
  const start = dayjs(date);
  const end = dayjs(date);

  return `${start.format('MMM DD, hh:mm')} - ${end
    .add(minutesRange, 'minute')
    .format('hh:mm A')}`;
};

export const formatTimeWithRange = (date: Date, minutesRange = 30) => {
  const startTime = dayjs(date).format('hh:mm');
  const endTime = dayjs(date).add(minutesRange, 'minute').format('hh:mm A');

  return `${startTime} - ${endTime}`;
};

export const formatToTimezoneDate = (date: Date | string, timezone?: string) =>
  dayjs(date).tz(timezone, false);

export const updateTimezone = (date: Date | string, timezone?: string) =>
  timezone
    ? dayjs(date, 'YYYY-DD-MMTHH:mm').tz(timezone, true).toDate()
    : new Date(date);

export const getNextEndDate = (startDate: Date, endDate: Date) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  if (start.isAfter(end) || start.isSame(endDate)) {
    const date = dayjs(startDate);
    return date
      .hour(11)
      .minute(0)
      .second(0)
      .millisecond(0)
      .add(1, 'day')
      .toDate();
  }

  return endDate;
};

export const subtract = (
  value: number,
  unit: dayjs.ManipulateType,
  date?: number | Date,
) => dayjs(date).subtract(value, unit).toDate();

export const isBefore = (date: number | Date, beforeDate: number | Date) =>
  dayjs(date).isBefore(dayjs(beforeDate));

// convert YYYY-MM-DD to Day Date Time Timezone format
export const formatDate = (date: Date) =>
  dayjs(date).hour(11).minute(0).second(0).millisecond(0).toDate();

export { dayjs };
