import React from 'react';
import { Box, Switch, Typography } from '@drivekyte/web-components';
import useTranslation from '@/hooks/use-translation';

type SameLocationTogglerProps = {
  value: boolean;
  onChange: (nextValue: boolean) => void;
  disabled?: boolean;
};

const SameLocationToggler = ({
  value,
  onChange,
  disabled,
}: SameLocationTogglerProps) => {
  const { t } = useTranslation('pages.landing-page.search-box.where');

  return (
    <Box
      display="flex"
      flexDirection="row"
      paddingHorizontal="xSmall"
      paddingVertical="tiny"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography color="primary" variant="label-3">
        {t('same-location-toggler')}
      </Typography>
      <Switch value={value} onChange={onChange} disabled={disabled} />
    </Box>
  );
};

export default SameLocationToggler;
