import { useQuery } from 'react-query';
import { SelfPickupSettings } from '@/types/self-pickup';
import request, { ApiResponse, RequestError } from '@/utils/request';
import {
  CACHE_DURATION_MS,
  STALE_DURATION_MS,
} from '@/constants/requests-configuration';

const fetchLots = async (serviceAreaUUID?: string) => {
  const { data: response } = await request<ApiResponse<SelfPickupSettings>>({
    url: `v1/customer-self-services/${serviceAreaUUID}/self-pickup-lots`,
  });

  return response.data;
};

const useFetchSelfPickupSettings = (serviceAreaUUID?: string) =>
  useQuery<SelfPickupSettings, RequestError>(
    ['self-pickup-settings', serviceAreaUUID],
    () => fetchLots(serviceAreaUUID),
    {
      enabled: !!serviceAreaUUID,
      cacheTime: CACHE_DURATION_MS.large,
      staleTime: STALE_DURATION_MS.large,
    },
  );

export default useFetchSelfPickupSettings;
