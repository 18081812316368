import SearchAddressModal from '@/components/search-address-modal';
import useVisibility from '@/hooks/use-visibility';
import { Typography } from '@drivekyte/ui';
import { Place } from '@drivekyte/use-places-search';
import { Box, Icon, Icons16 } from '@drivekyte/web-components';
import useAnalyticsWithBookingCart from '@/hooks/use-analytics-with-booking-cart-data';

type WhereItemProps = {
  title: string;
  placeholder: string;
  value?: string;
  iconName: Icons16;
  testID?: string;
  onChange: (address: Place) => void;
};

const WhereItem = ({
  title,
  placeholder,
  value,
  iconName,
  testID,
  onChange,
}: WhereItemProps) => {
  const [modalVisibility, showModal, hideModal] = useVisibility();
  const { trackEvent, AnalyticsEvents } = useAnalyticsWithBookingCart();

  const handleOnPress = () => {
    trackEvent(AnalyticsEvents.BookingFlow.SearchBarClicked);
    showModal();
  };

  return (
    <>
      <Box
        paddingHorizontal="xSmall"
        paddingVertical="tiny"
        display="flex"
        alignItems="center"
        testID={testID}
        onPress={handleOnPress}
      >
        <Box marginRight="xSmall">
          <Icon name={iconName} />
        </Box>
        <Box>
          <Box paddingRight="xSmall">
            <Typography variant="labelSmall">{title}</Typography>
          </Box>
          {value ? (
            <Typography variant="labelSmall">{value}</Typography>
          ) : (
            <Typography variant="labelSmall">{placeholder}</Typography>
          )}
        </Box>
      </Box>
      <SearchAddressModal
        title={title}
        visible={modalVisibility}
        onClose={hideModal}
        onSelect={onChange}
        searchDefaultValue={value}
      />
    </>
  );
};

export default WhereItem;
