import useTranslation from '@/hooks/use-translation';
import { Box, Typography } from '@drivekyte/ui';
import dynamic from 'next/dynamic';
import { SearchbarToggleProvider } from '@/pages/home-page/components/nav-bar/desktop-nav-bar-search/search-bar-context';

const Search = dynamic(() => import('./components/search'));

type HeroDesktopProps = {
  subHeader?: string;
  headline?: string;
  ctaText?: string;
};

const HeroDesktop = ({ headline, ctaText, subHeader }: HeroDesktopProps) => {
  const { t } = useTranslation('pages.home-page.hero');

  return (
    <Box width="auto" alignItems="center">
      <Box
        alignItems="center"
        width="100%"
        height={146}
        backgroundColor="$green700"
      >
        <Typography
          variant="headingXLarge"
          color="$contentInversePrimary"
          tag="h1"
        >
          {headline ?? t('headline')}
        </Typography>
        {subHeader && (
          <Typography
            variant="paragraphMedium"
            color="$green200"
            marginTop="$xTiny"
          >
            {subHeader}
          </Typography>
        )}
      </Box>
      <Box marginTop={-36}>
        <SearchbarToggleProvider>
          <Search ctaText={ctaText} />
        </SearchbarToggleProvider>
      </Box>
    </Box>
  );
};

export default HeroDesktop;
