import React from 'react';
import { Box } from '@drivekyte/web-components';
import useServiceAreaLabels, {
  ServiceAreaLabel,
} from '@/hooks/use-service-area-labels';
import SearchEmptyStateContent from './components/search-empty-state-content';
import SearchEmptyStateLoading from './components/search-empty-state-loading';
import ServiceAreaItem from './components/service-area-item';

type SearchEmptyStateProps = {
  onServiceAreaItemPress: (item: ServiceAreaLabel) => void;
  hideServiceAreas?: boolean;
  outsideServiceArea?: boolean;
};

const SearchEmptyState = ({
  onServiceAreaItemPress,
  hideServiceAreas,
  outsideServiceArea,
}: SearchEmptyStateProps) => {
  const { data, isLoading } = useServiceAreaLabels();

  return (
    <Box height="calc(100% - 148px)">
      {isLoading ? (
        <SearchEmptyStateLoading testID="search-empty-state-loading" />
      ) : (
        <>
          <SearchEmptyStateContent
            hideDivider={hideServiceAreas}
            outsideServiceArea={outsideServiceArea}
          />
          {!hideServiceAreas && (
            <Box marginTop="small" overflowY="auto" height="100%">
              {data?.map((serviceAreaLabel) => (
                <ServiceAreaItem
                  testID={`${serviceAreaLabel.code}-service-area-item`}
                  onPress={onServiceAreaItemPress}
                  key={`service-area-${serviceAreaLabel.code}`}
                  serviceAreaLabel={serviceAreaLabel}
                />
              ))}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default SearchEmptyState;
