import isEqual from 'lodash/isEqual';
import useFetchTimeSlots from './hooks/use-fetch-time-slots';
import { TimeSlot, UseTimeSlotsPayload } from './types';
import useDcsFlag from '../use-dcs-flag';
import DcsFlags from '@/types/dcs-flags';
import useBookingCart from '../use-booking-cart';

const useTimeSlots = (payload: UseTimeSlotsPayload) => {
  const { serviceAreaCode } = useBookingCart();

  const { isFlagEnabled: enableQuotesV3, isLoading: isLoadingV3Flag } =
    useDcsFlag({
      flag: DcsFlags.EnableQuotesV3,
      params: {
        start_address: payload.startAddress,
        end_address: payload.endAddress,
        service_area_name: payload.serviceAreaCode || serviceAreaCode,
      },
    });

  const { data, error, isLoading } = useFetchTimeSlots({
    ...payload,
    isEvaluatingV3Flag: isLoadingV3Flag,
    enableV3:
      enableQuotesV3 &&
      !!payload.serviceAreaUUID &&
      !!payload.endServiceAreaUUID,
  });

  const startTimeSlots: TimeSlot[] = data?.start.timeSlots ?? [];
  const endTimeSlots: TimeSlot[] = data?.end.timeSlots ?? [];

  const startTimeSlotsTimestamps = startTimeSlots.map((ts) => ({
    value: ts.timestamp,
    label: ts.label,
  }));

  const endTimeSlotsTimestamps = endTimeSlots.map((ts) => ({
    value: ts.timestamp,
    label: ts.label,
  }));

  const timeSlotsStartError = !!error && !startTimeSlots.length;
  const timeSlotsEndError = !!error && !startTimeSlots.length;

  const isStartDateValid = startTimeSlots.some((slot) =>
    isEqual(slot.value, payload.startDate),
  );
  const isEndDateValid = endTimeSlots.some((slot) =>
    isEqual(slot.value, payload.endDate),
  );

  return {
    data,
    error,
    isLoading: isLoading || isLoadingV3Flag,
    startTimeSlotsTimestamps,
    endTimeSlotsTimestamps,
    isStartDateValid,
    isEndDateValid,
    timeSlotsStartError,
    timeSlotsEndError,
  };
};

export default useTimeSlots;
