import { BookingCart, QueryBookingCart } from '@/types/booking-cart';
import DcsFlags from '@/types/dcs-flags';
import useBookingCart from '@/hooks/use-booking-cart';
import useDcsFlag from '@/hooks/use-dcs-flag';
import useSelfPickupSettings from './hooks/use-self-pickup-settings';

const useSelfPickup = () => {
  const { ...bookingCart } = useBookingCart();
  const { isFlagEnabled: isLegBasedSelfPickupEnabled } = useDcsFlag({
    flag: DcsFlags.EnableLegBasedSelfPickup,
    params: {
      service_area: bookingCart.serviceAreaCode,
    },
  });
  const {
    isEnabled,
    startLot,
    startLotPlace,
    startSelfPickupSettings,
    endLot,
    endLotPlace,
    endSelfPickupSettings,
    isLoading,
  } = useSelfPickupSettings(
    bookingCart.serviceAreaUUID,
    bookingCart.endServiceAreaUUID,
  );

  const { isStartAddressPickupAtLot, isEndAddressPickupAtLot } = bookingCart;

  const shouldUpdateSelfPickupOnBookingCart = (
    changes?: Partial<QueryBookingCart>,
  ): Partial<QueryBookingCart> => {
    if (!isEnabled) {
      return {};
    }

    if (!changes) {
      changes = bookingCart.toQuery();
    }

    const {
      start_address_exclusion_zone_id: startAddressExclusionZoneId,
      end_address_exclusion_zone_id: endAddressExclusionZoneId,
      is_mapping_start_to_pickup_at_lot: isMappingStartToPickupAtLot,
      is_mapping_end_to_pickup_at_lot: isMappingEndToPickupAtLot,
    } = changes;

    let nextBookingCart: Partial<BookingCart> = {
      isStartAddressPickupAtLot,
      isEndAddressPickupAtLot,
    };

    const nextStartAddress = changes.start_address || bookingCart.startAddress;
    const isNextStartAddressSelfPickup =
      nextStartAddress === startLot?.address ||
      nextStartAddress === startLotPlace?.display_text ||
      nextStartAddress === startLotPlace?.formatted_address;

    const nextEndAddress = changes.end_address || bookingCart.endAddress;
    const isNextEndAddressSelfPickup =
      nextEndAddress === endLot?.address ||
      nextEndAddress === endLotPlace?.display_text ||
      nextEndAddress === endLotPlace?.formatted_address;

    // If leg-based PUAL is disabled but one of the legs is a lot address, both legs should be set to the same lot address
    if (!isLegBasedSelfPickupEnabled) {
      if (
        (changes.start_address &&
          isNextStartAddressSelfPickup &&
          !startAddressExclusionZoneId) ||
        isMappingStartToPickupAtLot
      ) {
        return {
          is_start_address_pickup_at_lot: true,
          is_end_address_pickup_at_lot: true,
          end_address: changes.start_address,
          end_place: changes.start_place,
          end_timezone: changes.start_timezone,
          end_service_area_code: changes.service_area_code,
          end_service_area_uuid: changes.service_area_uuid,
          end_address_exclusion_zone_id:
            changes.start_address_exclusion_zone_id || '',
          same_delivery_and_return: true,
        };
      }

      if (
        (changes.end_address &&
          isNextEndAddressSelfPickup &&
          !endAddressExclusionZoneId) ||
        isMappingEndToPickupAtLot
      ) {
        return {
          is_start_address_pickup_at_lot: true,
          is_end_address_pickup_at_lot: true,
          start_address: changes.end_address,
          start_place: changes.end_place,
          start_timezone: changes.end_timezone,
          service_area_code: changes.end_service_area_code,
          service_area_uuid: changes.end_service_area_uuid,
          start_address_exclusion_zone_id:
            changes.end_address_exclusion_zone_id || '',
          same_delivery_and_return: true,
        };
      }

      return {
        is_start_address_pickup_at_lot: false,
        is_end_address_pickup_at_lot: false,
      };
    }

    if (
      (isNextStartAddressSelfPickup &&
        !isStartAddressPickupAtLot &&
        !startAddressExclusionZoneId) ||
      isMappingStartToPickupAtLot
    ) {
      nextBookingCart.isStartAddressPickupAtLot = true;
    } else if (!isNextStartAddressSelfPickup && isStartAddressPickupAtLot) {
      nextBookingCart.isStartAddressPickupAtLot = false;
    }

    if (
      (isNextEndAddressSelfPickup &&
        !isEndAddressPickupAtLot &&
        !endAddressExclusionZoneId) ||
      isMappingEndToPickupAtLot
    ) {
      nextBookingCart.isEndAddressPickupAtLot = true;
    } else if (!isNextEndAddressSelfPickup && isEndAddressPickupAtLot) {
      nextBookingCart.isEndAddressPickupAtLot = false;
    }

    return {
      is_start_address_pickup_at_lot: nextBookingCart.isStartAddressPickupAtLot,
      is_end_address_pickup_at_lot: nextBookingCart.isEndAddressPickupAtLot,
    };
  };

  return {
    isEnabled,
    isLegBasedSelfPickupEnabled,
    isStartAddressPickupAtLot,
    startLot,
    startLotPlace,
    startSelfPickupSettings,
    isEndAddressPickupAtLot,
    endLot,
    endLotPlace,
    endSelfPickupSettings,
    shouldUpdateSelfPickupOnBookingCart,
    isLotLoading: isLoading,
  };
};

export default useSelfPickup;
