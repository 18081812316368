// TODO: REMOVE
import { useDateTransformer } from '@drivekyte/date-utils';
import dayjs from 'dayjs';
import { TimeSlots, TimeSlotsApiResponse } from '../../types';

const useTimeSlotsTransformer = () => {
  const { getZonedTime } = useDateTransformer();

  return ({ start, end }: TimeSlotsApiResponse): TimeSlots => ({
    start: {
      timeSlots: start.timeslots.map((ms) => {
        const date = getZonedTime(ms, start.tz);

        return {
          label: dayjs(date).format('hh:mm A'),
          value: date,
          timestamp: date.getTime(),
        };
      }),
      tz: start.tz,
    },
    end: {
      timeSlots: end.timeslots.map((ms) => {
        const date = getZonedTime(ms, end.tz);
        return {
          label: dayjs(date).format('hh:mm A'),
          value: date,
          timestamp: date.getTime(),
        };
      }),
      tz: end.tz,
    },
  });
};

export default useTimeSlotsTransformer;
