import { useEffect } from 'react';
import create from 'zustand';
import usePlacesSearch, { Place } from '@/hooks/use-places-search-wrapper';
import useDcsFlag from '@/hooks/use-dcs-flag';
import useMe from '@/hooks/use-me/';
import DcsFlags from '@/types/dcs-flags';
import { SelfPickupSettings } from '@/types/self-pickup';
import useFetchSelfPickupSettings from '../use-fetch-self-pickup-settings';

export const isPlaceExclusionZoneLot = (place?: Place) =>
  place?.map_to?.exclusion_zone?.type === 'lot' ||
  place?.exclusion_zone?.type === 'lot';

type SelfPickupStore = {
  startSelfPickupSettings?: SelfPickupSettings;
  endSelfPickupSettings?: SelfPickupSettings;
  setStartSelfPickupSettings: (value?: SelfPickupSettings) => void;
  setEndSelfPickupSettings: (value?: SelfPickupSettings) => void;
};

const useSelfPickupStore = create<SelfPickupStore>((set) => ({
  startSelfPickupSettings: undefined,
  endSelfPickupSettings: undefined,
  setStartSelfPickupSettings: (selfPickupSettings?: SelfPickupSettings) =>
    set(() => ({ startSelfPickupSettings: selfPickupSettings })),
  setEndSelfPickupSettings: (selfPickupSettings?: SelfPickupSettings) =>
    set(() => ({ endSelfPickupSettings: selfPickupSettings })),
}));

const useSelfPickupSettings = (
  startServiceAreaUUID?: string,
  endServiceAreaUUID?: string,
) => {
  const { me } = useMe();
  const { data: selfPickupFlag } = useDcsFlag<SelfPickupSettings>({
    flag: DcsFlags.EnableSelfPickup,
    params: {
      email: me?.email,
    },
  });
  const {
    startSelfPickupSettings,
    endSelfPickupSettings,
    setStartSelfPickupSettings,
    setEndSelfPickupSettings,
  } = useSelfPickupStore();
  const startLot = startSelfPickupSettings?.lots[0];
  const endLot = endSelfPickupSettings?.lots[0];
  const { data: startLotPlaces, isLoading: startLotPlacesLoading } =
    usePlacesSearch(startLot?.address || '');
  const { data: endLotPlaces, isLoading: endLotPlacesLoading } =
    usePlacesSearch(endLot?.address || '');
  const { data: startData, refetch: refetchStartSettings } =
    useFetchSelfPickupSettings(startServiceAreaUUID);
  const { data: endData, refetch: refetchEndSettings } =
    useFetchSelfPickupSettings(endServiceAreaUUID);

  const isEnabled = !!(selfPickupFlag?.enable_web && (startLot || endLot));

  useEffect(() => {
    setStartSelfPickupSettings(startData);
  }, [JSON.stringify(startData?.service_area_instructions)]);

  useEffect(() => {
    setEndSelfPickupSettings(endData);
  }, [JSON.stringify(endData?.service_area_instructions)]);

  useEffect(() => {
    if (startServiceAreaUUID) {
      refetchStartSettings();
    }
  }, [startServiceAreaUUID]);

  useEffect(() => {
    if (endServiceAreaUUID) {
      refetchEndSettings();
    }
  }, [endServiceAreaUUID]);

  const isLoading = startLotPlacesLoading || endLotPlacesLoading;

  return {
    isEnabled,
    startLot,
    startLotPlace: startLotPlaces?.[0],
    startSelfPickupSettings,
    endLot,
    endLotPlace: endLotPlaces?.[0],
    endSelfPickupSettings,
    isLoading,
  };
};

export default useSelfPickupSettings;
