import { useCallback } from 'react';
import { Place } from '@drivekyte/use-places-search';
import create from 'zustand';
import useDcsFlag from '@/hooks/use-dcs-flag';
import DcsFlags from '@/types/dcs-flags';
import { EXCLUSION_ZONE_TYPE, HandoverOutpostType } from './constants';

export type HandoverOutpostState = {
  handoverOutpostStart?: Place;
  handoverOutpostEnd?: Place;
  handoverOutpostType?: HandoverOutpostType;
  requestedStart?: Place;
  requestedEnd?: Place;
  isConfirmationEnabled: boolean;
};

export type SuggestedHandoverValues = {
  state: HandoverOutpostState;
  setSuggestedStart: (requestedStart: Place) => void;
  setSuggestedEnd: (requestedEnd: Place) => void;
  toggleConfirmationStatus: () => void;
  acceptHandoverSuggestion: () => void;
  rejectHandoverSuggestions: () => void;
  checkHandoverAddress: (
    type: HandoverOutpostType,
    selectedPlace: Place,
  ) => boolean;
  isHandoverOutpost: (requestedPlace: Place) => boolean;
};

type SuggestedHandoverStoreValues = {
  state: HandoverOutpostState;
  setState: (newState: Partial<HandoverOutpostState>) => void;
};

const defaultState: HandoverOutpostState = {
  handoverOutpostStart: undefined,
  handoverOutpostEnd: undefined,
  handoverOutpostType: undefined,
  requestedStart: undefined,
  requestedEnd: undefined,
  isConfirmationEnabled: false,
};

const useHandoverOutpostStore = create<SuggestedHandoverStoreValues>((set) => ({
  state: defaultState,
  setState: (newState) =>
    set((store) => ({
      state: { ...store.state, ...newState },
    })),
}));

const useSuggestedHandover = (): SuggestedHandoverValues => {
  const { state, setState } = useHandoverOutpostStore();
  const { isFlagEnabled } = useDcsFlag<boolean>({
    flag: DcsFlags.EnableHandoverOutpost,
  });

  const isHandoverOutpost = useCallback(
    (requestedPlace: Place) => {
      const isWithinExclusionZone =
        requestedPlace.map_to?.exclusion_zone?.type === EXCLUSION_ZONE_TYPE;
      const isDifferentFromRedirectAddress =
        requestedPlace.map_to?.formatted_address !==
        requestedPlace.formatted_address;

      return Boolean(
        isFlagEnabled &&
          isWithinExclusionZone &&
          isDifferentFromRedirectAddress,
      );
    },
    [isFlagEnabled],
  );

  const toggleConfirmationStatus = useCallback(() => {
    setState({
      isConfirmationEnabled: !state.isConfirmationEnabled,
    });
  }, [setState, state.isConfirmationEnabled]);

  const resetStartSuggest = useCallback(
    (handoverState: HandoverOutpostState) => {
      setState({
        requestedStart: undefined,
        handoverOutpostStart: undefined,
        isConfirmationEnabled:
          handoverState.isConfirmationEnabled &&
          !!handoverState.handoverOutpostEnd,
        handoverOutpostType: handoverState.handoverOutpostEnd
          ? HandoverOutpostType.RETURN
          : undefined,
      });
    },
    [setState],
  );

  const resetEndSuggest = useCallback(
    (handoverState: HandoverOutpostState) => {
      setState({
        requestedEnd: undefined,
        handoverOutpostEnd: undefined,
        isConfirmationEnabled:
          handoverState.isConfirmationEnabled &&
          !!handoverState.handoverOutpostStart,
        handoverOutpostType: handoverState.handoverOutpostStart
          ? HandoverOutpostType.DELIVERY
          : undefined,
      });
    },
    [setState],
  );

  const setSuggestedStart = useCallback(
    (requestedStart: Place) => {
      if (isHandoverOutpost(requestedStart)) {
        setState({
          requestedStart: requestedStart,
          handoverOutpostStart: requestedStart.map_to,
          handoverOutpostType: HandoverOutpostType.DELIVERY,
        });
      } else {
        resetStartSuggest(state);
      }
    },
    [isHandoverOutpost, resetStartSuggest, setState, state],
  );

  const setSuggestedEnd = useCallback(
    (requestedEnd: Place) => {
      if (isHandoverOutpost(requestedEnd)) {
        setState({
          requestedEnd: requestedEnd,
          handoverOutpostEnd: requestedEnd.map_to,
          handoverOutpostType: HandoverOutpostType.RETURN,
        });
      } else {
        resetEndSuggest(state);
      }
    },
    [isHandoverOutpost, resetEndSuggest, setState, state],
  );

  const acceptHandoverSuggestion = useCallback(() => {
    if (state.handoverOutpostType === HandoverOutpostType.DELIVERY) {
      resetStartSuggest(state);
    } else if (state.handoverOutpostType === HandoverOutpostType.RETURN) {
      resetEndSuggest(state);
    } else {
      setState(defaultState);
    }
  }, [resetEndSuggest, resetStartSuggest, setState, state]);

  const rejectHandoverSuggestions = useCallback(() => {
    toggleConfirmationStatus();
  }, [toggleConfirmationStatus]);

  const checkHandoverAddress = useCallback(
    (type: HandoverOutpostType, selectedPlace: Place): boolean => {
      const hasSuggestedHandover = isHandoverOutpost(selectedPlace);

      if (!hasSuggestedHandover) {
        if (type === HandoverOutpostType.DELIVERY) {
          resetStartSuggest(state);
        } else {
          resetEndSuggest(state);
        }
        return false;
      }

      if (type === HandoverOutpostType.DELIVERY) {
        setSuggestedStart(selectedPlace);
      } else {
        setSuggestedEnd(selectedPlace);
      }

      return true;
    },
    [
      isHandoverOutpost,
      resetEndSuggest,
      resetStartSuggest,
      setSuggestedEnd,
      setSuggestedStart,
      state,
    ],
  );

  return {
    state,
    setSuggestedStart,
    setSuggestedEnd,
    toggleConfirmationStatus,
    acceptHandoverSuggestion,
    rejectHandoverSuggestions,
    checkHandoverAddress,
    isHandoverOutpost,
  };
};

export default useSuggestedHandover;
