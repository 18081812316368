import { Place } from '@drivekyte/use-places-search';

// Used to fix data since the redirect (map_to) place is returned from geo api without service area information
export const getEnhancedSuggestedPlace = (
  requestedPlace: Place,
  suggestedPlace: Place,
): Place => {
  return {
    ...suggestedPlace,
    service_area_code: requestedPlace.service_area_code,
    service_area_name: requestedPlace.service_area_name,
    service_area_uuid: requestedPlace.service_area_uuid,
  };
};
