import { useQuery } from 'react-query';
import request from '@/utils/request';
import useApiError from '../use-api-error/use-api-error';
import { formatFetchedSearchHistory } from '@/utils/format-search-history';
import { useDateFormatter } from '@drivekyte/date-utils';
import useRentalSearchHistory from '@/hooks/use-rental-search-history';

type RequestType = {
  uuid: string;
  formatISOWithoutTimezone: (date: Date) => string;
};

const fetchRentalSearchHistory = async ({
  uuid,
  formatISOWithoutTimezone,
}: RequestType) => {
  const { data: response } = await request({
    method: 'GET',
    url: `v1/users/${uuid}/search-history`,
  });

  return formatFetchedSearchHistory(response.data, formatISOWithoutTimezone);
};

const useGetRentalSearchHistory = ({
  uuid,
  enabled,
}: {
  uuid: string;
  enabled: boolean;
}) => {
  const showError = useApiError();
  const { updateCachedSearchHistory } = useRentalSearchHistory();
  const { formatISOWithoutTimezone } = useDateFormatter();

  return useQuery(
    ['rental-search-history', uuid],
    () => fetchRentalSearchHistory({ uuid, formatISOWithoutTimezone }),
    {
      enabled,
      onError: showError,
      onSuccess: (data) => {
        if (Array.isArray(data)) {
          updateCachedSearchHistory(data);
        }
      },
    },
  );
};

export default useGetRentalSearchHistory;
