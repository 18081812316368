import React from 'react';
import { Box, Divider, Typography } from '@drivekyte/web-components';
import { useMedia } from '@drivekyte/ui';
import useTranslation from '@/hooks/use-translation';

type SearchEmptyStateDesktopContentProps = {
  hideDivider?: boolean;
  outsideServiceArea?: boolean;
};

const SearchEmptyStateDesktopContent = ({
  hideDivider,
  outsideServiceArea,
}: SearchEmptyStateDesktopContentProps) => {
  const { t } = useTranslation(
    `components.search-empty-state.${
      outsideServiceArea ? 'outside-service-area' : 'no-results'
    }`,
  );
  const media = useMedia();

  return (
    <>
      <Typography color="primary" variant="headline-3" center>
        {t('title')}
      </Typography>
      <Typography color="quaternary" variant="body-1" center>
        {t('description')}
      </Typography>
      {!hideDivider && (
        <>
          <Box paddingVertical="base">
            <Divider />
          </Box>
          {!media.xs && (
            <Typography color="primary" variant="headline-3">
              {t('service-area-title')}
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default SearchEmptyStateDesktopContent;
