import { BookingCart } from '@/types/booking-cart';
import DcsFlags from '@/types/dcs-flags';
import useDcsFlag from '../use-dcs-flag/use-dcs-flag';

export type ServiceAreaLabel = {
  code: string;
  city: string;
  state: string;
  cityAddress: string;
};

/**
 * Fetches City and State data from DCS
 * TODO: Can remove this hook if/when fleet/ops provides this data
 * https://gitlab.com/drivekyte/kytecoredevelopers/kyte-team-input/-/issues/1636
 */
const useServiceAreaLabels = (
  serviceAreaCode?: BookingCart['serviceAreaCode'],
) => {
  const query = useDcsFlag<{ labels: Array<ServiceAreaLabel> }>({
    flag: DcsFlags.ServiceAreaLabels,
    params: {
      service_area: serviceAreaCode,
    },
  });
  const labels = query.data?.labels;
  return {
    ...query,
    data: labels?.map((label) => ({
      ...label,
      cityAddress: `${label.city}, ${label.state}`,
    })),
  };
};

export default useServiceAreaLabels;
