import useDcsFlag from '@/hooks/use-dcs-flag';
import DcsFlags from '@/types/dcs-flags';
import { MerchandisingConfigFlag } from '@/types/flags';
import { PromoCode } from '@/types/quote';

const useMerchandisingConfig = (promoCode?: PromoCode) => {
  const couponType = promoCode?.percent_off ? 'percent_off' : 'amount_off';

  const { data, isLoading } = useDcsFlag<MerchandisingConfigFlag>({
    flag: DcsFlags.MerchandisingConfig,
    params: {
      coupon_type: couponType,
    },
    options: {
      enabled: !!promoCode,
    },
  });

  return { data, isLoading };
};

export { useMerchandisingConfig };
