import React from 'react';
import { Box, Icon, Pressable, Typography } from '@drivekyte/web-components';
import { ServiceAreaLabel } from '@/hooks/use-service-area-labels';

type ServiceAreaItemProps = {
  testID?: string;
  serviceAreaLabel: ServiceAreaLabel;
  onPress: (item: ServiceAreaLabel) => void;
};

const ServiceAreaItem = ({
  serviceAreaLabel,
  testID,
  onPress,
}: ServiceAreaItemProps) => (
  <>
    <Pressable
      testID={testID}
      onPress={() => {
        onPress(serviceAreaLabel);
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        marginBottom="small"
      >
        <Icon name="24-pin" />
        <Box marginLeft="xSmall">
          <Typography color="quaternary" variant="body-1">
            {serviceAreaLabel.cityAddress}
          </Typography>
        </Box>
      </Box>
    </Pressable>
  </>
);

export default ServiceAreaItem;
