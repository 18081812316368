import { useDateTransformer } from '@drivekyte/date-utils';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import useBookingCart from '@/hooks/use-booking-cart';
import useCurrentCurrency from '@/hooks/use-current-currency';
import { PreBookingParams } from '@/types/booking';
import { Quote } from '@/types/quote';
import request, { ApiResponse, RequestError } from '@/utils/request';

type UseQuotesConfig = {
  disabled?: boolean;
};

const fetchQuotes = async (params: PreBookingParams) => {
  const { data: response } = await request<ApiResponse<Quote[]>>({
    url: 'v3/rentals/quotes',
    params,
  });

  return response?.data;
};

const useQuotes = ({ disabled }: UseQuotesConfig = {}) => {
  const { updateCurrentCurrency } = useCurrentCurrency();
  const bookingCart = useBookingCart();
  const { getDateInServiceAreaTz } = useDateTransformer();
  const router = useRouter();

  let params: PreBookingParams = {
    end_address: bookingCart.endAddress,
    end_date: getDateInServiceAreaTz(
      bookingCart.endDate.getTime(),
      bookingCart.endTz,
    ),
    end_address_exclusion_zone_id: bookingCart.endAddressExclusionZoneId,
    start_address: bookingCart.startAddress,
    start_date: getDateInServiceAreaTz(
      bookingCart.startDate.getTime(),
      bookingCart.startTz,
    ),
    start_address_exclusion_zone_id: bookingCart.startAddressExclusionZoneId,
    is_start_address_pickup_at_lot: bookingCart.isStartAddressPickupAtLot,
    is_end_address_pickup_at_lot: bookingCart.isEndAddressPickupAtLot,
    coupon_code: router.query ? (router.query.code as string) : '',
  };

  const enabled =
    !disabled &&
    !!(
      bookingCart.endAddress &&
      bookingCart.endDate &&
      bookingCart.startAddress &&
      bookingCart.startDate
    );

  return useQuery<Quote[], RequestError, Array<Quote>>(
    [
      'quotes',
      bookingCart.endAddress,
      params.end_date,
      bookingCart.startAddress,
      params.start_date,
      bookingCart.startAddressExclusionZoneId,
      bookingCart.endAddressExclusionZoneId,
      bookingCart.isStartAddressPickupAtLot,
      bookingCart.isEndAddressPickupAtLot,
    ],
    () => fetchQuotes(params),
    {
      enabled,
      retry: false,
      onSuccess: (data) => updateCurrentCurrency(data[0]?.currency),
    },
  );
};

export default useQuotes;
