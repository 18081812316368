import React, { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { MetaTags } from '@/types/meta-tags';
import useAnalyticsWithSource from '@/hooks/use-analytics-with-source';

export type PageProps = {
  children: React.ReactNode;
  title: string;
  top: {
    mobile: number;
    web: number;
  };
  metaTags?: MetaTags;
};

const Page = ({ children, title, top, metaTags }: PageProps) => {
  const router = useRouter();
  const { trackPageView } = useAnalyticsWithSource();

  useEffect(() => {
    trackPageView(router.pathname, {
      query: router.query,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  return (
    <Container mobilePaddingTop={top.mobile} webPaddingTop={top.web}>
      <Head>
        <title>{title}</title>
        {metaTags &&
          Object.keys(metaTags).map((tag: string) => (
            <meta
              name={tag.includes('og:') ? undefined : tag}
              property={tag.includes('og:') ? tag : undefined}
              content={metaTags ? metaTags[tag as keyof MetaTags] : ''}
              key={tag}
            />
          ))}
      </Head>
      <main>{children}</main>
    </Container>
  );
};

const Container = styled.div<{
  mobilePaddingTop: number;
  webPaddingTop: number;
}>`
  padding-top: ${({ mobilePaddingTop }) => mobilePaddingTop}px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: ${({ webPaddingTop }) => webPaddingTop}px;
  }
`;

export default Page;
