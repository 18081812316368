import React, { useState } from 'react';
import { Box, Icon } from '@drivekyte/web-components';
import useTranslation from '@/hooks/use-translation';
import { formatDateTime } from '@/utils/date-formatter';
import EnhancedCalendar from '../../../enhanced-calendar';
import { Typography } from '@drivekyte/ui';

type WhenProps = {
  endDate: Date;
  endAddress: string;
  startDate: Date;
  startAddress: string;
  calendarTestID?: string;
  serviceAreaUUID?: string;
  endServiceAreaUUID?: string;
  onChangeDates: (
    nextEndDate: string,
    nextStartDate: string,
    nextEndTimeZone?: string,
    nextStartTimeZone?: string,
  ) => void;
};

const When = ({
  endDate,
  endAddress,
  startDate,
  startAddress,
  calendarTestID,
  serviceAreaUUID,
  endServiceAreaUUID,
  onChangeDates,
}: WhenProps) => {
  const { t } = useTranslation('pages.landing-page.search-box.when');
  const [calendarVisibility, setCalendarVisibility] = useState(false);
  const [tempDates, setTempDates] = useState({
    startDate,
    endDate,
  });

  return (
    <>
      <Box
        backgroundColor="elevated"
        borderBottomWidth="large"
        borderColor="primary"
        borderRadius="base"
        borderWidth="large"
        boxShadow="base"
        marginTop="xSmall"
        borderStyle="solid"
        testID="responsive-search-box-when"
        onPress={() => setCalendarVisibility(true)}
      >
        <Box
          display="flex"
          flexDirection="row"
          padding="tiny"
          borderColor="primary"
          alignItems="center"
        >
          <Box marginRight="xSmall" alignItems="center" justifyContent="center">
            <Icon name="16-calendar" color="primary" />
          </Box>
          <Box display="flex" flexDirection="row">
            <Box marginRight="tiny">
              <Typography color="$backgroundPositive" variant="labelSmall">
                {t('from')}
              </Typography>
              <Box marginTop="tiny">
                <Typography color="$backgroundPositive" variant="labelSmall">
                  {t('to')}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography color="primary" variant="labelSmall">
                {formatDateTime(tempDates.startDate)}
              </Typography>
              <Box marginTop="tiny">
                <Typography color="primary" variant="labelSmall">
                  {formatDateTime(tempDates.endDate)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <EnhancedCalendar
        testID={calendarTestID}
        setDates={setTempDates}
        onClose={() => setCalendarVisibility(false)}
        visible={calendarVisibility}
        onSubmit={onChangeDates}
        endDate={tempDates.endDate}
        endAddress={endAddress}
        startDate={tempDates.startDate}
        startAddress={startAddress}
        serviceAreaUUID={serviceAreaUUID}
        endServiceAreaUUID={endServiceAreaUUID}
      />
    </>
  );
};

export default When;
