import { useQuery } from 'react-query';
import AnalyticsEvents from '@/config/analytics-events';
import {
  LiveDataSocialProof,
  LiveDataSocialProofParams,
} from '@/types/live-data-social-proof';
import useAnalyticsWithSource from '@/hooks/use-analytics-with-source';
import request, { RequestError } from '@/utils/request';

const fetchLiveDataSocialProof = async (params: LiveDataSocialProofParams) => {
  const { data: response } = await request({
    url: 'v1/social-proof',
    params,
  });

  return response.data;
};

const useLiveDataSocialProof = (params: LiveDataSocialProofParams) => {
  const { trackEvent } = useAnalyticsWithSource();

  return useQuery<LiveDataSocialProof, RequestError>(
    ['live-data-social-proof', params],
    () => fetchLiveDataSocialProof(params),
    {
      enabled: !!params.service_area_uuid,
      onSuccess: (data) => {
        if (!data) {
          trackEvent(
            AnalyticsEvents.LiveDataSocialProofEvents
              .LiveDataSocialProofDisabled,
            {
              client_component_id: params.client_component_id,
              service_area_uuid: params.service_area_uuid,
            },
          );
        }
      },
    },
  );
};

export default useLiveDataSocialProof;
