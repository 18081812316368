import { useNotification } from '@drivekyte/web-components';
import { RequestError } from '@/utils/request';

const useApiError = (title?: string) => {
  const notify = useNotification();

  return (error: RequestError) =>
    notify({
      descriptionText: error.response?.data.status ?? error.message,
      titleText: title || 'Error',
      variant: 'error',
    });
};

export default useApiError;
