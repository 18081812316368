import { Place } from '@drivekyte/use-places-search';
import { ExclusionZoneTypeEnum } from '@drivekyte/use-places-search/lib/use-places-search';

export const getIsMappingToLot = (place: Place) => {
  const mappedPlace = place.map_to;

  if (!mappedPlace) return false;

  const isPlaceToDisplayTextValid = mappedPlace.display_text;
  const isPlaceALot =
    mappedPlace.exclusion_zone?.type === ExclusionZoneTypeEnum.Lot;

  return !!(isPlaceToDisplayTextValid && isPlaceALot);
};
