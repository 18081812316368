import React from 'react';
import { useDateFormatter } from '@drivekyte/date-utils';
import { Calendar, createTimePickerOptions } from '@drivekyte/web-components';
import dayjs from 'dayjs';
import useCalendarValidDates from '@/hooks/use-calendar-valid-dates/use-calendar-valid-dates';
import useTimeSlots from '@/hooks/use-time-slots';
import useTranslation from '@/hooks/use-translation';
import { QueryBookingCart } from '@/types/booking-cart';

type Dates = {
  startDate: Date;
  endDate: Date;
};

type EnhancedCalendarProps = {
  onClose: () => void;
  onSubmit: (
    nextEndDate: string,
    nextStartDate: string,
    nextEndTimeZone?: string,
    nextStartTimeZone?: string,
    disableCloseSearchBox?: boolean,
  ) => void;
  setDates: ({ startDate, endDate }: Dates) => void;
  visible: boolean;
  endDate: Date;
  endAddress: string;
  startDate: Date;
  startAddress: string;
  testID?: string;
  disableEditStartDate?: boolean;
  disableStartTime?: boolean;
  disableEndTime?: boolean;
  disableStartTimeslotAlteration?: boolean;
  disableEndTimeslotAlteration?: boolean;
  serviceAreaUUID?: string;
  endServiceAreaUUID?: string;
  isStartAddressPickupAtLot?: boolean;
  isEndAddressPickupAtLot?: boolean;
};

const EnhancedCalendar = ({
  onClose,
  onSubmit,
  visible,
  testID,
  endAddress,
  endDate,
  startAddress,
  startDate,
  disableEditStartDate,
  disableStartTime,
  disableEndTime,
  setDates,
  disableStartTimeslotAlteration,
  disableEndTimeslotAlteration,
  serviceAreaUUID,
  endServiceAreaUUID,
  isStartAddressPickupAtLot,
  isEndAddressPickupAtLot,
}: EnhancedCalendarProps) => {
  const { t } = useTranslation('pages.landing-page.search-box.when.calendar');
  const { formatISOWithoutTimezone } = useDateFormatter();

  const {
    data,
    isLoading,
    startTimeSlotsTimestamps,
    endTimeSlotsTimestamps,
    timeSlotsStartError,
    timeSlotsEndError,
  } = useTimeSlots({
    endAddress,
    startAddress,
    endDate: endDate || startDate,
    startDate: startDate,
    serviceAreaUUID,
    endServiceAreaUUID,
    startHandoverType: isStartAddressPickupAtLot ? 'pual' : undefined,
    endHandoverType: isEndAddressPickupAtLot ? 'pual' : undefined,
  });

  useCalendarValidDates({
    startTimeSlotsTimestamps,
    endTimeSlotsTimestamps,
    disableStartTimeslotAlteration,
    disableEndTimeslotAlteration,
    startTz: data?.start.tz,
    endTz: data?.end.tz,
    selectedDates: { startDate, endDate },
    onChange: (newBookingCart: Partial<QueryBookingCart>) =>
      handleForceUpdateCalendar(newBookingCart),
  });

  const handleDateChanged = (nextStartDate: Date, nextEndDate: Date) =>
    setDates({ startDate: nextStartDate, endDate: nextEndDate });

  const handleForceUpdateCalendar = ({
    start_date,
    end_date,
    start_timezone,
    end_timezone,
  }: Partial<QueryBookingCart>) => {
    const newStartDate = start_date
      ? new Date(start_date as string)
      : startDate;
    const newEndDate = end_date ? new Date(end_date as string) : endDate;

    onSubmit(
      formatISOWithoutTimezone(newEndDate),
      formatISOWithoutTimezone(newStartDate),
      end_timezone,
      start_timezone,
      true,
    );

    if (start_date && end_date) {
      setDates({
        startDate: newStartDate,
        endDate: newEndDate,
      });
    }
  };

  const handleSubmit = (
    nextStartDate: Date,
    nextEndDate?: Date,
    disableCloseSearchBox?: boolean,
  ) => {
    if (nextStartDate && nextEndDate) {
      if (data?.end.tz && data.start.tz) {
        onSubmit(
          formatISOWithoutTimezone(nextEndDate),
          formatISOWithoutTimezone(nextStartDate),
          data?.end.tz,
          data?.start.tz,
          disableCloseSearchBox,
        );
      }
      setDates({
        startDate: nextStartDate,
        endDate: nextEndDate,
      });
    }
  };

  return (
    <Calendar
      testID={testID}
      headerTitleText={t('title')}
      emptyStartDateText={t('empty-state.start-date')}
      emptyEndDateText={t('empty-state.end-date')}
      emptyTimeslotsText={t('empty-state.timeslots')}
      loading={isLoading}
      submitButtonText={t('cta')}
      endDate={endDate}
      onRequestToClose={onClose}
      onChangeDates={handleDateChanged}
      onSubmit={handleSubmit}
      visible={visible}
      startDate={startDate}
      startTimePickerOptions={
        (disableEditStartDate
          ? createTimePickerOptions(7, 22, startDate, '2-digit')
          : data?.start.timeSlots) ??
        createTimePickerOptions(7, 22, startDate, '2-digit')
      }
      endTimePickerOptions={
        data?.end.timeSlots ??
        createTimePickerOptions(7, 22, endDate, '2-digit')
      }
      datePickerProps={{
        fromMonth: startDate,
        disabledDays: [
          {
            before: disableEditStartDate ? startDate : new Date(),
          },
        ],
        initialMonth: startDate,
        toMonth: dayjs(startDate).add(1, 'year').toDate(),
      }}
      disableEditStartDate={disableEditStartDate}
      disableStartTime={disableStartTime || timeSlotsStartError}
      disableEndTime={disableEndTime || timeSlotsEndError}
    />
  );
};

export default EnhancedCalendar;
