import usePartnerReferral from '@/hooks/use-partner-referral';
import usePush from '@/hooks/use-push';
import useSearch from '@/hooks/use-search';
import { QueryBookingCart } from '@/types/booking-cart';
import { Box } from '@drivekyte/web-components';
import dynamic from 'next/dynamic';
import React from 'react';
import When from './components/when';
import Where from './components/where';

type SearchBoxProps = {
  children?: React.ReactNode;
  calendarTestID?: string;
  onClose?: () => void;
};

const HandoverOutpostConfirmationDialog = dynamic(() =>
  import('@/components/handover-outpost-confirmation'),
);

const SearchBox = ({ children, calendarTestID, onClose }: SearchBoxProps) => {
  const push = usePush();
  const { hasToRefineAddress } = usePartnerReferral();
  const {
    bookingCart,
    onChange: onChangeSearch,
    onChangeAddressToggle,
    handoverOutpostState,
    acceptHandoverSuggestion,
    rejectHandoverSuggestions,
  } = useSearch({ disableTimeslotFetching: true });

  const {
    startPlace,
    startAddress,
    endPlace,
    endAddress,
    startDate,
    endDate,
    isSameDeliveryReturnLocations,
    serviceAreaUUID,
    endServiceAreaUUID,
  } = bookingCart;

  const startHandoverAddress = startPlace || startAddress;
  const endHandoverAddress = endPlace || endAddress;

  const handleChangeAddress = (nextAddressQuery: Partial<QueryBookingCart>) => {
    push({ ...nextAddressQuery, refined_address: true });
    if (onClose) onClose();
  };

  const handleChangeDates = (
    nextEndDate: string,
    nextStartDate: string,
    nextEndTimeZone?: string,
    nextStartTimeZone?: string,
    disableCloseSearchBox?: boolean,
  ) => {
    onChangeSearch({
      end_date: nextEndDate,
      end_timezone: nextEndTimeZone,
      start_date: nextStartDate,
      start_timezone: nextStartTimeZone,
    });
    if (onClose && !disableCloseSearchBox) onClose();
  };

  return (
    <Box display="flex" flexDirection="column">
      <Where
        isSameDeliveryReturnLocations={isSameDeliveryReturnLocations}
        onChangeAddress={handleChangeAddress}
        onChangeAddressToggle={onChangeAddressToggle}
        startAddress={hasToRefineAddress ? undefined : startHandoverAddress}
        endAddress={hasToRefineAddress ? undefined : endHandoverAddress}
      />
      <When
        calendarTestID={calendarTestID}
        startDate={startDate}
        startAddress={startAddress}
        endDate={endDate}
        endAddress={endAddress}
        serviceAreaUUID={serviceAreaUUID}
        endServiceAreaUUID={endServiceAreaUUID}
        onChangeDates={handleChangeDates}
      />
      {children}
      {handoverOutpostState.isConfirmationEnabled && (
        <HandoverOutpostConfirmationDialog
          visible
          handoverOutpostState={handoverOutpostState}
          onSubmit={acceptHandoverSuggestion}
          onClose={rejectHandoverSuggestions}
        />
      )}
    </Box>
  );
};

export default SearchBox;
