import { useMemo } from 'react';
import usePlaceDetails from '@/hooks/use-place-details';
import { AddressValidationTranslationEnum } from '@/types/booking';

type Addresses = {
  startAddress: string;
  endAddress: string;
};

const useVerifyAddresses = ({ startAddress, endAddress }: Addresses) => {
  const sameAddresses = startAddress === endAddress;
  const {
    data: startData,
    isFetching: isFetchingStartData,
    isError: startError,
  } = usePlaceDetails(startAddress);
  const {
    data: endData,
    isFetching: isFetchingEndData,
    isError: endError,
  } = usePlaceDetails(endAddress, !sameAddresses);

  const getVagueEnum = () => {
    if (sameAddresses || (startData?.vague && endData?.vague))
      return 'vague.delivery-and-return';

    if (startData?.vague) return 'vague.delivery';

    return 'vague.return';
  };

  const getOutOfServiceAreaEnum = () => {
    if (sameAddresses || (!startData?.service_area && !endData?.service_area))
      return 'out-of-service.delivery-and-return';

    if (!startData?.service_area) return 'out-of-service.delivery';

    return 'out-of-service.return';
  };

  const errorMessage = useMemo((): AddressValidationTranslationEnum => {
    if (startError || endError) return '';

    if (startData?.vague || endData?.vague) {
      return getVagueEnum();
    }

    if (!startData?.service_area || !endData?.service_area) {
      return getOutOfServiceAreaEnum();
    }

    return '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startData, endData, startError, endError]);

  const isLoading = isFetchingStartData || isFetchingEndData;
  const hasInvalidAddress = !!errorMessage && !isLoading;
  const isVagueStartAddress = !!startData?.vague;
  const isVagueEndAddress = !!endData?.vague;

  return {
    isLoading,
    errorMessage,
    startPlaceDetails: startData,
    endPlaceDetails: sameAddresses ? startData : endData,
    hasInvalidAddress,
    isVagueStartAddress,
    isVagueEndAddress,
  };
};

export default useVerifyAddresses;
