import { TimeSlotTimestamp } from '../use-time-slots/types';

const useNextAvailableTimeslot = () => {
  const getNextAvailableTimeslot = (
    currentDateSelection: Date,
    timeslots: TimeSlotTimestamp[],
  ) => {
    const currentTimeSelection =
      typeof currentDateSelection === 'number'
        ? new Date(currentDateSelection).getTime()
        : currentDateSelection.getTime();

    const compatibleTimeslot = timeslots.filter(
      (timeslot) => timeslot.value === currentTimeSelection,
    )[0];

    if (compatibleTimeslot) return compatibleTimeslot;

    const nearestTimeslotAfter = timeslots.filter(
      (timeslot) => timeslot.value > currentTimeSelection,
    );

    if (nearestTimeslotAfter.length) return nearestTimeslotAfter[0];

    const nearestTimeslotBefore = timeslots
      .filter((timeslot) => timeslot.value < currentTimeSelection)
      .pop();

    if (nearestTimeslotBefore) return nearestTimeslotBefore;

    return timeslots[0];
  };

  return {
    getNextAvailableTimeslot,
  };
};

export default useNextAvailableTimeslot;
